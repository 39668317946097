
import { defineComponent } from "vue";

export default defineComponent({
  name: "file",
  props: {
    fileTitle: String,
    shortenFileTitle: {
      type: Boolean,
      default: true,
    },
    createdAt: String,
    fileType: String,
  },
  components: {},
});
