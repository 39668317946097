import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f236f34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 col-sm-12 col-xl" }
const _hoisted_2 = { class: "card h-100 file-wrapper" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "text-gray-700 text-hover-primary d-flex flex-column" }
const _hoisted_5 = { class: "symbol symbol-40px mb-3" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "fs-7 fw-bold text-gray-400 mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card-body d-flex justify-content-center text-center flex-column p-3",
        style: {"max-width":"140px"},
        title: _ctx.fileTitle
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: `/media/svg/files/${_ctx.fileType}.svg`,
              alt: ""
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["fs-7 fw-bolder mb-2 file-title", { shorten: _ctx.shortenFileTitle }])
          }, _toDisplayString(_ctx.fileTitle), 3)
        ]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.createdAt), 1)
      ], 8, _hoisted_3)
    ])
  ]))
}