<template>
  <div>
    <!--begin::Card-->
    <div class="card">
      <div class="card-header align-items-center py-5 gap-5">
        <!--begin::Actions-->
        <div class="d-block w-100">
          <!--begin::Back-->
          <router-link
            to="/emails"
            class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Previous"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
            <span class="svg-icon svg-icon-1 m-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="11"
                  width="13"
                  height="2"
                  rx="1"
                  fill="black"
                />
                <path
                  d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                  fill="black"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </router-link>
          <!--end::Back-->

          <div v-if="patient" class="float-right">
            <router-link
              class="btn btn-sm btn-light"
              :to="{ name: 'patient', params: { id: patient.id } }"
            >
              <i class="bi bi-box-arrow-up-right me-1 mb-1" />
              Patient's profile
            </router-link>
          </div>
          <!-- <div v-else-if="messages.length">
            Create patient
          </div> -->
        </div>
        <!--end::Actions-->
      </div>
      <div class="card-body" v-if="messages.length">
        <!--begin::Title-->
        <div class="d-flex flex-wrap gap-2 justify-content-between mb-8">
          <div class="d-flex align-items-center flex-wrap gap-2">
            <!--begin::Heading-->
            <h2 class="fw-bold me-3 my-1">
              {{ messages[0].subject }}
            </h2>
            <!--end::Heading-->
          </div>
        </div>
        <!--end::Title-->

        <!-- <h5 class="mt-7 mb-0">Previous messages</h5> -->
        <!--begin::Message thread-->
        <div class="accordion">
          <div
            v-for="message in previousMessages"
            :key="message.id"
            class="accordion-item"
          >
            <div class="email separator my-6"></div>
            <!-- <div class="separator my-6"></div> -->
            <!--begin::Message accordion-->
            <div data-kt-inbox-message="message_wrapper">
              <!--begin::Message header-->
              <div
                class="
                  d-flex
                  flex-wrap
                  gap-2
                  flex-stack
                  cursor-pointer
                  accordion-header
                  collapsed
                "
                data-bs-toggle="collapse"
                :data-bs-target="`#email_${message.id}`"
                aria-expanded="false"
                :aria-controls="`#email_${message.id}`"
              >
                <!--begin::Author-->
                <div class="d-flex align-items-center">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-35px me-3">
                    <div class="symbol-label bg-light-danger">
                      <span class="text-danger">
                        {{ toInitials(message.from) }}
                      </span>
                    </div>
                  </div>
                  <!--end::Avatar-->
                  <div class="pe-5">
                    <!--begin::Author details-->
                    <div class="d-flex align-items-center flex-wrap gap-1">
                      <a
                        href="#"
                        class="fw-bolder text-dark text-hover-primary"
                        >{{ message.from }}</a
                      >
                    </div>
                    <!--end::Author details-->
                    <!--begin::Message details-->
                    <div class="d-none" data-kt-inbox-message="details">
                      <span class="text-muted fw-bold">
                        to <span>{{ message.to.join(", ") }}</span>
                        <span v-if="lastMessage.replyTo" class="d-block"
                          >reply to {{ lastMessage.replyTo }}</span
                        >
                      </span>
                    </div>
                    <!--end::Message details-->
                  </div>
                </div>
                <!--end::Author-->
                <!--begin::Actions-->
                <div class="d-flex align-items-center flex-wrap gap-2">
                  <!--begin::Date-->
                  <span class="fw-bold text-muted text-end me-3">
                    <i
                      v-if="message.attachments.length"
                      class="fas fa-paperclip mr-2"
                    />
                    {{ message.date }}
                  </span>
                  <!--end::Date-->
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Message header-->
              <!--begin::Message content-->
              <div class="collapse fade" data-kt-inbox-message="message">
                <div class="py-5">
                  {{ message.snippet }}
                </div>
              </div>
              <!--end::Message content-->
            </div>
            <div
              class="accordion-collapse collapse"
              :id="`email_${message.id}`"
            >
              <div class="my-6 message-content" v-html="message.content" />
              <div class="d-flex">
                <div
                  v-for="attachment in message.attachments"
                  :key="`attachment-${message.id}-${attachment.name}`"
                >
                  <file
                    @click.prevent="
                      (e) => openContextMenu(e, attachment, message.from)
                    "
                    :file-title="attachment.name"
                    :file-type="mimeTypeToFile(attachment.mimeType)"
                  />
                </div>
              </div>
            </div>
            <!--end::Message accordion-->
          </div>
        </div>
        <div class="separator my-6" id="last-message"></div>

        <!--begin::Message accordion-->
        <div v-if="lastMessage" data-kt-inbox-message="message_wrapper">
          <!--begin::Message header-->
          <div
            class="d-flex flex-wrap gap-2 flex-stack cursor-pointer"
            data-kt-inbox-message="header"
          >
            <!--begin::Author-->
            <div class="d-flex align-items-center">
              <!--begin::Avatar-->
              <div class="symbol symbol-35px me-3">
                <div class="symbol-label bg-light-danger">
                  <span class="text-danger">
                    {{ toInitials(lastMessage.from) }}
                  </span>
                </div>
              </div>
              <!--end::Avatar-->
              <div class="pe-5">
                <!--begin::Author details-->
                <div class="d-flex align-items-center flex-wrap gap-1">
                  <a href="#" class="fw-bolder text-dark text-hover-primary">
                    {{ lastMessage.from }}
                  </a>
                </div>
                <!--end::Author details-->
                <!--begin::Message details-->
                <div data-kt-inbox-message="details">
                  <span class="text-muted fw-bold">
                    to <span>{{ lastMessage.to.join(", ") }}</span>
                    <span v-if="lastMessage.replyTo" class="d-block"
                      >reply to {{ lastMessage.replyTo }}</span
                    >
                  </span>
                </div>
                <!--end::Message details-->
                <!--begin::Preview message-->
                <div
                  class="text-muted fw-bold mw-450px d-none"
                  data-kt-inbox-message="preview"
                  v-html="lastMessage.snippet"
                ></div>
                <!--end::Preview message-->
              </div>
            </div>
            <!--end::Author-->
            <!--begin::Actions-->
            <div class="d-flex align-items-center flex-wrap gap-2">
              <!--begin::Date-->
              <span class="fw-bold text-muted text-end me-3">
                <i
                  v-if="lastMessage.attachments.length"
                  class="fas fa-paperclip mr-2"
                />
                {{ lastMessage.date }}
              </span>
              <!--end::Date-->
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Message header-->
          <!--begin::Message content-->
          <div class="collapse fade show" data-kt-inbox-message="message">
            <div class="py-5 message-content" v-html="lastMessage.content" />
            <div class="d-flex">
              <div
                v-for="attachment in lastMessage.attachments"
                :key="`attachment-${lastMessage.id}-${attachment.name}`"
              >
                <file
                  @click.prevent="
                    (e) => openContextMenu(e, attachment, lastMessage.from)
                  "
                  :file-title="attachment.name"
                  :file-type="mimeTypeToFile(attachment.mimeType)"
                />
              </div>
            </div>
          </div>
          <!--end::Message content-->
        </div>
        <!--end::Message accordion-->

        <!--begin::Form-->
        <new
          v-if="patient"
          :is-reply="true"
          :subject="lastMessage.subject"
          :recipient="patient.email"
          :thread-id="lastMessage.threadId"
          :headers="getLastMessageHeaders()"
          @email-sent="onEmailSent()"
        />
        <new
          v-else
          :is-reply="true"
          :subject="lastMessage.subject"
          :recipient="lastMessage.from"
          :thread-id="lastMessage.threadId"
          :headers="getLastMessageHeaders()"
          @email-sent="onEmailSent()"
        />
        <!--end::Form-->
      </div>
    </div>
    <!--end::Card-->

    <context-menu
      v-model:show="showAttachmentContextMenu"
      :options="attachmentContextMenuOptions"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import File from "@/components/files/File";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  console.log("timeago");
}
import { useToast } from "vue-toastification";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import New from "@/views/apps/email/New";

export default {
  name: "Email",
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    New,
    File,
  },
  data() {
    return {
      timeAgo: null,
      messages: [],
      patient: null,

      showAttachmentContextMenu: false,
      attachmentContextMenuOptions: {
        items: [],
        iconFontClass: "iconfont",
        customClass: "class-a",
        minWidth: 160,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    previousMessages() {
      return this.messages.slice(0, -1);
    },
    lastMessage() {
      return this.messages[this.messages.length - 1];
    },
  },
  mounted() {
    this.timeAgo = new TimeAgo("en-US");
    setCurrentPageBreadcrumbs("View Email", [
      { label: "Emails", to: "/emails" },
    ]);
    this.fetchThread();
  },
  methods: {
    openContextMenu(e, attachment) {
      //Show menu
      this.showAttachmentContextMenu = true;
      this.attachmentContextMenuOptions.x = e.x;
      this.attachmentContextMenuOptions.y = e.y;
      this.attachmentContextMenuOptions.items = [
        {
          label: "Show",
          onClick: () => {
            this.showAttachment(attachment);
          },
        },
        {
          label: "Add to Patient",
          onClick: () => {
            this.saveAttachmentToPatient(attachment);
          },
        },
      ];
    },
    fetchThread() {
      ApiService.get("gmail/threads", this.$route.params.id).then(
        ({ data: { parsedMessages } }) => {
          this.messages = parsedMessages;

          this.fetchPatientFromThread();

          setTimeout(() => {
            document
              .getElementById("last-message")
              .scrollIntoView({ behavior: "smooth" });
          }, 800);

          setTimeout(() => {
            if (this.$route.params.id) {
              ApiService.post(`gmail/threads/${this.$route.params.id}/read`);
            }
          }, 2000);
        }
      );
    },
    /**
     * Refresh thread when an email has been sent
     */
    onEmailSent() {
      console.log("email sent");
      this.fetchThread();
    },
    /**
     * Retrieve important headers from last thread message
     * to keep replies in the same Thread
     * @return {Object} Headers { inReplyTo, references }
     */
    getLastMessageHeaders() {
      const headers = {};

      const inReplyTo = this.lastMessage.headers.find(
        (e) => e.name === "Message-Id"
      );
      headers.inReplyTo = inReplyTo ? inReplyTo.value : null;

      const references = this.lastMessage.headers.find(
        (e) => e.name === "References"
      );
      headers.references = references ? references.value : null;

      return headers;
    },
    /**
     * Show the given attachment in a new tab
     * @param {Object} attachment { title, mimeType, base64 }
     */
    showAttachment(attachment) {
      fetch(`data:${attachment.mimeType};base64,${attachment.base64}`)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUri = URL.createObjectURL(blob);
          console.log(blobUri);
          window.open(blobUri, "_blank");
        });
    },
    fetchPatientEmailFromThread() {
      const clinicEmail = JwtService.getUser().clinic.email;

      for (const message of this.messages) {
        const potentialPatientEmails = [...message.to];
        if (message.replyTo) potentialPatientEmails.push(message.replyTo);
        if (message.from) potentialPatientEmails.push(message.from);

        for (const potentialPatientEmail of potentialPatientEmails) {
          // Clean email
          const regex = /<(.*)>/g; // The actual regex
          const matches = regex.exec(potentialPatientEmail);
          const cleanedPotentialEmail =
            matches && matches.length ? matches[1] : potentialPatientEmail;

          if (cleanedPotentialEmail != clinicEmail) {
            return cleanedPotentialEmail;
          }
        }
      }

      return "";
    },
    fetchPatientFromThread() {
      const from = this.fetchPatientEmailFromThread();

      ApiService.query("patients", { params: { email: from } })
        .then(({ data }) => {
          console.log(data);
          if (data.results.length === 1) {
            this.patient = data.results[0];
          }
          // else if (data.results.length > 1) {
          //   alert('Multiple patient have this email address');
          // } else {
          //   alert('No matching patient found');
          // }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Automatically save an attachment to the matching patient
     */
    saveAttachmentToPatient(attachment, email) {
      if (this.patient) {
        const config = {
          responseToast: {
            text: "Files saved successfully.",
          },
        };
        ApiService.post(
          `patients/${this.patient.id}/documents`,
          {
            filename: attachment.name,
            mimetype: attachment.mimeType,
            description: null,
            content: attachment.base64,
          },
          config
        )
          .then(({ data }) => {
            console.log(data);
          })
      } else {
        this.toast.error("No matching patient found");
      }
    },
    /**
     * Convert a string to initials (e.g. John Doe -> JD)
     * @param {String} str
     * @return {String}
     */
    toInitials(str) {
      try {
        return str
          .match(/\b(\w)/g)
          .join("")
          .slice(0, 2)
          .toUpperCase();
      } catch (err) {
        return str.substring(0, 2).toUpperCase();
      }
    },
    /**
     * Convert a Mime Type to a file type understandable by child components
     * @param {String} type e.g. 'application/pdf'
     * @return {String} e.g. 'pdf'
     */
    mimeTypeToFile(type) {
      if (type === "application/pdf") return "pdf";
      else return "upload";
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  border-color: transparent;
}

.message-content {
  max-width: 900px;
  overflow-x: scroll;
}
</style>